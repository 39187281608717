import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/Logo.png';
import { useNavigate } from 'react-router-dom';
import WebStorageCache from 'web-storage-cache';
import { LoadingContext } from '../utils/LoadingContext';
import { useLocation } from 'react-router-dom';
import './Sidebar.css';


const webStorageCache = new WebStorageCache();


const Sidebar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);


  const { isLoading } = useContext(LoadingContext);
  useEffect(() => {
    const fetchDataFromLocalStorage = async () => {
      if (document.getElementById('value_ticket')) {
        document.getElementById('value_ticket').textContent = localStorage.getItem('nTickets') || '0';
      }
      if (document.getElementById('value_ticket2')) {
        document.getElementById('value_ticket2').textContent = localStorage.getItem('nTickets') || '0';
      }
      if (document.getElementById('value_bozze')) {
        document.getElementById('value_bozze').textContent = localStorage.getItem('nTicketsBozze') || '0';
      }
      if (document.getElementById('value_bozze_2')) {
          document.getElementById('value_bozze_2').textContent = localStorage.getItem('nTicketsBozze') || '0';
      }
    };
    fetchDataFromLocalStorage();

  }, []);


  const navigate = useNavigate();
  const handleLogout = () => {
    // Rimuovi le informazioni dal WebStorageCache
    webStorageCache.delete('isLoggedIn');
    webStorageCache.delete('token');
    webStorageCache.delete('refresh_token');
    webStorageCache.delete('salesforceId');
    webStorageCache.delete('expires_at');
    webStorageCache.delete('expires');
    webStorageCache.delete('sedi');
    webStorageCache.delete('categorie');
    webStorageCache.delete('questionBlocks');
    webStorageCache.delete('editTicketId');
    webStorageCache.delete('cover');
    localStorage.removeItem('nTickets');
    localStorage.removeItem('nTicketsBozze');
    localStorage.removeItem('tickets');
    localStorage.removeItem('ticketsBozze');
    localStorage.removeItem('email');
    localStorage.removeItem('templates');
    navigate('/login');
  };



  return (
      <nav className={`p-4 sticky-top bg-light sidebar ${window.innerWidth <= 800 ? 'col-2' : 'col-3'}`}
           style={{height: "100vh", position: "fixed"}}>
        <Link to="/dashboard">
          <img alt="Turismo Torino e Provincia" height="100" src={logo}
               className="d-inline-block align-text-top responsive-image"/>
        </Link>
        <hr/>
        <ul className="nav nav-pills flex-column">
          <li className="nav-item">
            <Link to="/dashboard" onClick={() => setActiveLink("/dashboard")}
                  className={`nav-link fw-bold ${activeLink === "/dashboard" ? 'active' : ''}`} aria-current="page">
              <i className="bi bi-speedometer2"></i>
              Dashboard
            </Link>
          </li>
          <li>
            <Link to={'/tickets/Tickets'} state={{from: "Tickets"}} onClick={() => setActiveLink("/tickets/Tickets")}
                  className={`nav-link link-dark ${activeLink === "/tickets/Tickets" ? 'active' : ''} ${isLoading ? 'disabled' : ''}`}>
              <i className="bi bi-ticket"></i>
              Ticket <span id="value_ticket" className="badge btn-success">0</span>
            </Link>
          </li>
          <li>
            <Link to={'/tickets/Bozze'} state={{from: "Bozze"}} onClick={() => setActiveLink("/tickets/Bozze")}
                  className={`nav-link link-dark ${activeLink === "/tickets/Bozze" ? 'active' : ''} ${isLoading ? 'disabled' : ''}`}>
              <i className="bi bi-file-earmark-text"></i>
              Bozze <span id="value_bozze_2" className="badge btn-success">0</span>
            </Link>
          </li>
          <hr/>
          <li>
            <Link to="/crea-ticket" onClick={() => setActiveLink("/crea-ticket")}
                  className={`nav-link link-dark ${activeLink === "/crea-ticket" ? 'active' : ''} ${isLoading ? 'disabled' : ''}`}>
              <i className="bi bi-plus"></i>
              Crea ticket
            </Link>
          </li>

        </ul>
        <div className="d-grid gap-2 mt-3">
          <button onClick={handleLogout} className="btn btn-outline-dark">Esci</button>
        </div>
      </nav>
  );
};

export default Sidebar;