const selectedValueMap = {
    resources: 'risorse',
    events: 'eventi',
    products: 'prodotti',
    tables: 'tabelle',
    altro: 'altro'
};

export const handleInputRisorse = (e, selectedIds, setSelectedIds, urlSearch) => {
    if (e.target.id.startsWith('validationRS') && e.target.id !== 'validationRS') {
        let questionNumber = e.target.id.replace('validationRS', '');
        document.getElementById('validationRS' + questionNumber).style.backgroundColor = "#ffffff";
        let selectedValue = document.getElementById('validationTdR' + questionNumber).value || '';
        let inputText = e.target.value || '';
        let uniqueHits = new Set();
        let inputElement = document.getElementById('validationRS' + questionNumber);
        let dataList = document.getElementById('datalist' + questionNumber);

        if (dataList && Array.from(dataList.options).some(option => option.value.trim().toLowerCase() === e.target.value.trim().toLowerCase())) {
            return;
        }

        if (!dataList) {
            dataList = document.createElement('datalist');
            dataList.id = 'datalist' + questionNumber;
            document.body.appendChild(dataList); // Append to body instead of inputElement
        }
        dataList.innerHTML = '';

        let sameValueCount = {};

        if (selectedValue === 'tables') {
            let tables = JSON.parse(localStorage.getItem('tabelle')) || [];
            setTimeout(() => {
                tables.forEach(table => {
                    let textContent = table.id + ' - ' + table.denominazione + ' - tabelle';
                    if (!uniqueHits.has(textContent)) {
                        uniqueHits.add(textContent);

                        if(!sameValueCount[table.denominazione]) {
                            sameValueCount[table.denominazione] = 0;
                        }
                        sameValueCount[table.denominazione] += 1;

                        let value = table.denominazione;

                        if(sameValueCount[table.denominazione] > 1) {
                            //aggiungi uno spazio per ogni ripetizione
                            for(let i = 0; i < sameValueCount[table.denominazione]; i++) {
                                value += ' ';
                            }
                        }

                        let option = document.createElement('option');
                        option.value = value;
                        option.textContent = textContent;
                        dataList.appendChild(option);
                    }
                });
                addChangeListener(inputElement, dataList, setSelectedIds);
            }, 0); // Add a small delay
        } else {
            if (inputText.length < 3) {
                return;
            }
            let body = {
                q: inputText,
                page: 1,
            };
            fetch(urlSearch, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => response.json())
            .then(data => {
                setTimeout(() => {
                    for (let i = 0; i < data.hits.length; i++) {
                        let collection = data.hits[i].collection;
                        if ((selectedValue === '' && (collection === 'risorse' || collection === 'eventi' || collection === 'prodotti')) ||
                            (selectedValueMap[selectedValue] && collection === selectedValueMap[selectedValue])) {
                            let id = data.hits[i].id;
                            let textContent = id + ' - ' + data.hits[i].titolo + ' - ' + collection;
                            if (!uniqueHits.has(textContent)) {
                                uniqueHits.add(textContent);
                                if (!Array.from(dataList.options).some(option => option.textContent === textContent)) {

                                    if (!sameValueCount[data.hits[i].titolo]) {
                                        sameValueCount[data.hits[i].titolo] = 0;
                                    }
                                    sameValueCount[data.hits[i].titolo] += 1;
                                    let value = data.hits[i].titolo;
                                    if(sameValueCount[data.hits[i].titolo] > 1) {
                                        for(let i = 0; i < sameValueCount[data.hits[i].titolo]; i++) {
                                            value += ' ';
                                        }
                                    }

                                    let option = document.createElement('option');
                                    option.value = value;
                                    option.textContent = textContent;
                                    dataList.appendChild(option);
                                }
                            }
                        }
                    }
                    let page = 2;
                    let maxPages = Math.min(data.totalPages || 1, 10);

                    for (page; page <= maxPages; page++) {
                        body.page = page;
                        fetch(urlSearch, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })
                        .then(response => response.json())
                        .then(data => {
                            for (let i = 0; i < data.hits.length; i++) {
                                let collection = data.hits[i].collection;
                                if ((selectedValue === '' && (collection === 'risorse' || collection === 'eventi' || collection === 'prodotti')) ||
                                    (selectedValueMap[selectedValue] && collection === selectedValueMap[selectedValue])) {
                                    let id = data.hits[i].id;
                                    let textContent = id + ' - ' + data.hits[i].titolo + ' - ' + collection;
                                    if (!uniqueHits.has(textContent)) {
                                        uniqueHits.add(textContent);
                                        if (!Array.from(dataList.options).some(option => option.textContent === textContent)) {

                                            if (!sameValueCount[data.hits[i].titolo]) {
                                                sameValueCount[data.hits[i].titolo] = 0;
                                            }
                                            sameValueCount[data.hits[i].titolo] += 1;
                                            let value = data.hits[i].titolo;
                                            if(sameValueCount[data.hits[i].titolo] > 1) {
                                                for(let i = 0; i < sameValueCount[data.hits[i].titolo]; i++) {
                                                    value += ' ';
                                                }
                                            }

                                            let option = document.createElement('option');
                                            option.value = value;
                                            option.textContent = textContent;
                                            dataList.appendChild(option);
                                        }
                                    }
                                }
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });
                    }
                }, 0); // Add a small delay
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .then(() => {
                observeDataList(inputElement, dataList, setSelectedIds);
            });
        }
    }
}

function observeDataList(inputElement, dataList, setSelectedIds) {
    const observer = new MutationObserver(() => {
        if (dataList.options.length > 0) {
            observer.disconnect();
            addChangeListener(inputElement, dataList, setSelectedIds);
        }
    });

    observer.observe(dataList, { childList: true });
}

//TODO: test option con stesso nome modificando find di selectedOption: DA TESTARE
function addChangeListener(inputElement, dataList, setSelectedIds) {
    inputElement.addEventListener('change', function (e) {
        let optionsArray = Array.from(dataList.options);
        let selectedOption = optionsArray.find(option =>
            option.value.toLowerCase() === this.value.toLowerCase()
        );

        if (selectedOption) {
            let selectedId = selectedOption.textContent.split(' - ')[0];
            let collectionParts = selectedOption.textContent.split(' - ');
            let collection = collectionParts[collectionParts.length - 1];
            let currentSelectedIds = JSON.parse(sessionStorage.getItem('selectedIds')) || {};
            var questionNumber = e.target.id.replace('validationRS', '');
            if (selectedId !== currentSelectedIds[questionNumber]) {
                currentSelectedIds[questionNumber] = selectedId;
                sessionStorage.setItem('selectedIds', JSON.stringify(currentSelectedIds));
                setSelectedIds(currentSelectedIds);
            }
            document.getElementById('validationRS' + questionNumber).style.backgroundColor = "#ccffcc";
            //console.log('collection: ' + collection);
            switch (collection) {
                case "risorse":
                    document.getElementById('validationTdR' + questionNumber).value = "resources";
                    break;
                case "eventi":
                    document.getElementById('validationTdR' + questionNumber).value = "events";
                    break;
                case "prodotti":
                    document.getElementById('validationTdR' + questionNumber).value = "products";
                    break;
                case "tabelle":
                    document.getElementById('validationTdR' + questionNumber).value = "tables";
                    break;
                default:
                    document.getElementById('validationTdR' + questionNumber).value = "altro";
                    break;
            }
        }
    });
}