// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-family: 'Switzer', sans-serif;
    font-size: 1rem;
}

.nav-link.active,
.btn-success {
    background-color: #5ac4ed !important;
}

.btn-outline-success {
    border-color: #5ac4ed !important;
    color: #5ac4ed !important;
}

.btn-outline-success:hover {
    background-color: #5ac4ed !important;
    color: #fff !important;
}

.bg-success {
    background-color: #5ac4ed !important;
}

nav.navbar,
.mob {
    display: none;
}

#editor {
    height: 300px;
}

@media (max-width: 799px) {

    nav.col-3,
    .no-mob {
        display: none;
    }

    nav.navbar,
    .mob {
        display: block;
    }
}
.no-padding-left {
    padding-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/CreaTicket/CreateTicketPage.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,eAAe;AACnB;;AAEA;;IAEI,oCAAoC;AACxC;;AAEA;IACI,gCAAgC;IAChC,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;;IAEI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;;IAEI;;QAEI,aAAa;IACjB;;IAEA;;QAEI,cAAc;IAClB;AACJ;AACA;IACI,0BAA0B;AAC9B","sourcesContent":["* {\r\n    font-family: 'Switzer', sans-serif;\r\n    font-size: 1rem;\r\n}\r\n\r\n.nav-link.active,\r\n.btn-success {\r\n    background-color: #5ac4ed !important;\r\n}\r\n\r\n.btn-outline-success {\r\n    border-color: #5ac4ed !important;\r\n    color: #5ac4ed !important;\r\n}\r\n\r\n.btn-outline-success:hover {\r\n    background-color: #5ac4ed !important;\r\n    color: #fff !important;\r\n}\r\n\r\n.bg-success {\r\n    background-color: #5ac4ed !important;\r\n}\r\n\r\nnav.navbar,\r\n.mob {\r\n    display: none;\r\n}\r\n\r\n#editor {\r\n    height: 300px;\r\n}\r\n\r\n@media (max-width: 799px) {\r\n\r\n    nav.col-3,\r\n    .no-mob {\r\n        display: none;\r\n    }\r\n\r\n    nav.navbar,\r\n    .mob {\r\n        display: block;\r\n    }\r\n}\r\n.no-padding-left {\r\n    padding-left: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
