// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-family: 'Switzer', sans-serif;
    font-size: 1rem;
}

.nav-link.active, .btn-success {
    background-color: #5ac4ed!important;
}

.btn-outline-success {
    border-color: #5ac4ed!important;
    color: #5ac4ed!important;
}

.btn-outline-success:hover {
    background-color: #5ac4ed!important;
    color: #fff!important;
}

.bg-success {
    background-color: #5ac4ed!important;
}

nav.navbar, .mob {
    display: none;
}

@media (max-width: 799px) {
    nav.col-3, .no-mob {

    }

    .responsive-margin {
        margin-left: 125px !important;
    }

    nav.navbar, .mob {
        display: block;
    }
}
.modal {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 200px;
    height: 200px;
}

.no-margin-left {
    margin-left: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/DashboardPage.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,+BAA+B;IAC/B,wBAAwB;AAC5B;;AAEA;IACI,mCAAmC;IACnC,qBAAqB;AACzB;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;;IAEA;;IAEA;QACI,6BAA6B;IACjC;;IAEA;QACI,cAAc;IAClB;AACJ;AACA;IACI,eAAe;IACf,aAAa;IACb,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["* {\r\n    font-family: 'Switzer', sans-serif;\r\n    font-size: 1rem;\r\n}\r\n\r\n.nav-link.active, .btn-success {\r\n    background-color: #5ac4ed!important;\r\n}\r\n\r\n.btn-outline-success {\r\n    border-color: #5ac4ed!important;\r\n    color: #5ac4ed!important;\r\n}\r\n\r\n.btn-outline-success:hover {\r\n    background-color: #5ac4ed!important;\r\n    color: #fff!important;\r\n}\r\n\r\n.bg-success {\r\n    background-color: #5ac4ed!important;\r\n}\r\n\r\nnav.navbar, .mob {\r\n    display: none;\r\n}\r\n\r\n@media (max-width: 799px) {\r\n    nav.col-3, .no-mob {\r\n\r\n    }\r\n\r\n    .responsive-margin {\r\n        margin-left: 125px !important;\r\n    }\r\n\r\n    nav.navbar, .mob {\r\n        display: block;\r\n    }\r\n}\r\n.modal {\r\n    position: fixed;\r\n    z-index: 9999;\r\n    left: 0;\r\n    top: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: rgba(0,0,0,0.4);\r\n}\r\n\r\n.modal-content {\r\n    background-color: #fefefe;\r\n    padding: 20px;\r\n    border: 1px solid #888;\r\n    width: 200px;\r\n    height: 200px;\r\n}\r\n\r\n.no-margin-left {\r\n    margin-left: 0 !important;\r\n}\r\n\r\n.no-padding-left {\r\n    padding-left: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
