import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';

export default function applyAutocomplete() {
    $("#validationComune, #validationComuneRS").autocomplete({
        source: function(request, response) {
            $.ajax({
                url: "https://secure.geonames.org/searchJSON",
                dataType: "jsonp",
                data: {
                    username: "federico.favole",
                    name_startsWith: request.term,
                    featureClass: "P",
                    maxRows: 10,
                    lang: "it"
                },
                success: function(data) {
                    response($.map(data.geonames, function(item) {
                        return {
                            label: item.name + (item.adminName1 ? ", " + item.adminName1 : "") + ", " + item.countryName,
                            value: item.name,
                            country: item.countryName,
                            region: item.adminName1
                        };
                    }));
                }
            });
        },
        minLength: 2,
        select: function(event, ui) {
            if (this.id === "validationComune") {
                $("#validationNazione").val(ui.item.country);
                $("#validationRegione").val(ui.item.region);
            } else if (this.id === "validationComuneRS") {
                $("#validationNazioneRS").val(ui.item.country);
            }
        }

    });

    $("#validationRegione").autocomplete({
        source: function(request, response) {
            $.ajax({
                url: "https://secure.geonames.org/searchJSON",
                dataType: "jsonp",
                data: {
                    username: "federico.favole",
                    name_startsWith: request.term,
                    featureCode: "ADM1",
                    country: "IT",
                    maxRows: 10,
                    lang: "it"
                },
                success: function(data) {
                    response($.map(data.geonames, function(item) {
                        return {
                            label: item.name + ", " + item.countryName,
                            value: item.name,
                            country: item.countryName
                        };
                    }));
                }
            });
        },
        minLength: 2,
        select: function(event, ui) {
            $("#validationNazione").val(ui.item.country);
        }
    });
}