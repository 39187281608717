export const professioni = [
    "Archeologo",
    "Architetto",
    "Assegnista",
    "Avvocato",
    "Cameraman",
    "Chef",
    "Commercialista",
    "Conduttore",
    "Designer",
    "Dietologo",
    "Editor",
    "Egittologo",
    "Geografo",
    "Iconografo",
    "Interior Designer",
    "Militare",
    "Prefetto",
    "Presentatore",
    "Procuratore",
    "Redattore Web",
    "Regista",
    "Scrittore",
    "Showman",
    "Social Media Manager",
    "Sociologo",
    "Vescovo",
    "Web Content Manager",
    "Web Designer",
    "Webmaster",
    "Accompagnatore cicloturistico",
    "Accompagnatore di turismo equestre",
    "Accompagnatore naturalistico o Guida Escursionistica ambientale",
    "Accompagnatori di media montagna",
    "Guida Alpina",
    "Istruttore di canoa e kayak"
];

export const contacts = [
    { firstName: "Sabrina", lastName: "Marretta", personalEmail: "s.marretta@turismotorino.org", activityEmail: "info.desk@turismotorino.org" },
    { firstName: "Erika", lastName: "Carosso", personalEmail: "e.carosso@turismotorino.org", activityEmail: "resguide@turismotorino.org" },
    { firstName: "Elisa", lastName: "Alessi", personalEmail: "e.alessi@turismotorino.org", activityEmail: "reshotel@turismotorino.org" },
    { firstName: "Monica", lastName: "Piras", personalEmail: "m.piras@turismotorino.org", activityEmail: "shop@turismotorino.org" },
    { firstName: "Salvatrice", lastName: "Contarino", personalEmail: "s.contarino@turismotorino.org", activityEmail: "info.desk@turismotorino.org" },
    { firstName: "Chiara", lastName: "Varetto", personalEmail: "c.varetto@turismotorino.org", activityEmail: "resguide@turismotorino.org" },
    { firstName: "Antonella", lastName: "Francolino", personalEmail: "a.francolino@turismotorino.org", activityEmail: "shop@turismotorino.org" },
    { firstName: "Manuela", lastName: "Buzio", personalEmail: "m.buzio@turismotorino.org", activityEmail: "shop@turismotorino.org" },
    { firstName: "Alessia", lastName: "Timon", personalEmail: "a.timon@turismotorino.org", activityEmail: "info.bardonecchia@turismotorino.org" },
    { firstName: "Roberta", lastName: "Deyme", personalEmail: "r.deyme@turismotorino.org", activityEmail: "info.bardonecchia@turismotorino.org" },
    { firstName: "Giovanna", lastName: "Callet", personalEmail: "g.callet@turismotorino.org", activityEmail: "info.bardonecchia@turismotorino.org" },
    { firstName: "Benedetta", lastName: "Zacchero", personalEmail: "b.zacchero@turismotorino.org", activityEmail: "info.cesana@turismotorino.org" },
    { firstName: "Antonella", lastName: "Milia", personalEmail: "a.milia@turismotorino.org", activityEmail: "info.cesana@turismotorino.org" },
    { firstName: "Loredana", lastName: "Rivetti", personalEmail: "l.rivetti@turismotorino.org", activityEmail: "info.claviere@turismotorino.org" },
    { firstName: "Alessandra", lastName: "Brun", personalEmail: "a.brun@turismotorino.org", activityEmail: "info.claviere@turismotorino.org" },
    { firstName: "Iside", lastName: "Negrinotti", personalEmail: "i.negrinotti@turismotorino.org", activityEmail: "info.ivrea@turismotorino.org" },
    { firstName: "Claudia", lastName: "Cossano", personalEmail: "c.cossano@turismotorino.org", activityEmail: "info.ivrea@turismotorino.org" },
    { firstName: "Daniela", lastName: "Benech", personalEmail: "d.benech@turismotorino.org", activityEmail: "info.pinerolo@turismotorino.org" },
    { firstName: "Monica", lastName: "Berton", personalEmail: "m.berton@turismotorino.org", activityEmail: "info.pragelato@turismotorino.org" },
    { firstName: "Agnes", lastName: "Rostan", personalEmail: "a.rostan@turismotorino.org", activityEmail: "info.pragelato@turismotorino.org" },
    { firstName: "Milena", lastName: "Blanc", personalEmail: "m.blanc@turismotorino.org", activityEmail: "info.pragelato@turismotorino.org" },
    { firstName: "Cinzia", lastName: "Eydallin", personalEmail: "c.eydallin@turismotorino.org", activityEmail: "info.sauze@turismotorino.org" },
    { firstName: "Federica", lastName: "Perron", personalEmail: "f.perron@turismotorino.org", activityEmail: "info.sauze@turismotorino.org" },
    { firstName: "Antonella", lastName: "Valsesia", personalEmail: "a.valsesia@turismotorino.org", activityEmail: "info.sestriere@turismotorino.org" },
    { firstName: "Simona", lastName: "Crespi", personalEmail: "s.crespi@turismotorino.org", activityEmail: "info.sestriere@turismotorino.org" },
    { firstName: "Susy", lastName: "Sibille", personalEmail: "s.sibille@turismotorino.org", activityEmail: "info.susa@turismotorino.org" },
    { firstName: "Sofia", lastName: "Pecchenino", personalEmail: "s.pecchenino@turismotorino.org", activityEmail: "info.susa@turismotorino.org" },
    { firstName: "Elisa", lastName: "Alessi", personalEmail: "e.alessi@turismotorino.org", activityEmail: "reshotel@turismotorino.org" },
    { firstName: "Elena Adele", lastName: "Zappia", personalEmail: "e.zappia@turismotorino.org", activityEmail: "reshotelolimpico@turismotorino.org" },
    { firstName: "Iside", lastName: "Negrinotti", personalEmail: "i.negrinotti@turismotorino.org", activityEmail: "resguidereale@turismotorino.org" },
    { firstName: "Erika", lastName: "Carosso", personalEmail: "e.carosso@turismotorino.org", activityEmail: "resguide@turismotorino.org" },
    { firstName: "Chiara", lastName: "Varetto", personalEmail: "c.varetto@turismotorino.org", activityEmail: "resguide@turismotorino.org" },
    { firstName: "Iside", lastName: "Negrinotti", personalEmail: "i.negrinotti@turismotorino.org", activityEmail: "resguidereale@turismotorino.org" },
    { firstName: "Claudia", lastName: "Cossano", personalEmail: "c.cossano@turismotorino.org", activityEmail: "resguidereale@turismotorino.org" },
    { firstName: "Benedetta", lastName: "Lovecchio", personalEmail: "b.lovecchio@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Sabrina", lastName: "Marretta", personalEmail: "s.marretta@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Erika", lastName: "Carosso", personalEmail: "e.carosso@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Elisa", lastName: "Alessi", personalEmail: "e.alessi@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Monica", lastName: "Piras", personalEmail: "m.piras@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Salvatrice", lastName: "Contarino", personalEmail: "s.contarino@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Chiara", lastName: "Varetto", personalEmail: "c.varetto@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Antonella", lastName: "Francolino", personalEmail: "a.francolino@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Manuela", lastName: "Buzio", personalEmail: "m.buzio@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Alessandra", lastName: "Tormena", personalEmail: "a.tormena@turismotorino.org", activityEmail: "promozione@turismotorino.org" },
    { firstName: "Roberta", lastName: "Rossetti", personalEmail: "r.rossetti@turismotorino.org", activityEmail: "promozione@turismotorino.org" },
    { firstName: "Alberto", lastName: "Surico", personalEmail: "a.surico@turismotorino.org", activityEmail: "promozione@turismotorino.org" },
    { firstName: "Veronica", lastName: "Rossi", personalEmail: "v.rossi@turismotorino.org", activityEmail: "promozione@turismotorino.org" },
    { firstName: "Elisa", lastName: "Pilot", personalEmail: "e.pilot@turismotorino.org"},
    { firstName: "Roberta", lastName: "Tealdi", personalEmail: "r.tealdi@turismotorino.org"},
    { firstName: "Grazia", lastName: "Taccetta", personalEmail: "g.taccetta@turismotorino.org"},
    { firstName: "Cristina", lastName: "Cerutti", personalEmail: "c.cerutti@turismotorino.org"},
    { firstName: "Silvia", lastName: "Lanza", personalEmail: "s.lanza@turismotorino.org"},
    { firstName: "Federica", lastName: "Borini", personalEmail: "f.borini@turismotorino.org"},
    { firstName: "Sara", lastName: "Solera", personalEmail: "s.solera@turismotorino.org"},
    { firstName: "Raffaella", lastName: "Brizzi", personalEmail: "r.brizzi@turismotorino.org"},
    { firstName: "Elena Adele", lastName: "Zappia", personalEmail: "e.zappia@turismotorino.org"},
    { firstName: "Devid", lastName: "Perelli", personalEmail: "d.perelli@turismotorino.org"},
    { firstName: "Francesca", lastName: "Sibilla", personalEmail: "f.sibilla@turismotorino.org" },
    { firstName: "Nadia", lastName: "Stara", personalEmail: "n.stara@turismotorino.org" },
    { firstName: "Valeria", lastName: "De Marchi", personalEmail: "v.demarchi@turismotorino.org" },
    { firstName: "Federica", lastName: "Gai", personalEmail: "f.gai@turismotorino.org" },
    { firstName: "Cristina", lastName: "Paolasso", personalEmail: "c.paolasso@turismotorino.org" },
    { firstName: "Paola", lastName: "Lamborghini", personalEmail: "p.lamborghini@turismotorino.org" },
    { firstName: "Giada", lastName: "Armando", personalEmail: "g.armando@turismotorino.org" },
    { firstName: "Mariangela", lastName: "Chiapperini", personalEmail: "m.chiapperini@turismotorino.org" },
    { firstName: "Elena", lastName: "Bruson", personalEmail: "e.bruson@turismotorino.org" },
    { firstName: "Chiara", lastName: "Benevenuta", personalEmail: "c.benevenuta@turismotorino.org" },
    { firstName: "Arianna", lastName: "Cheik", personalEmail: "a.cheik@turismotorino.org" },
    { firstName: "Monia", lastName: "Valle", personalEmail: "m.valle@turismotorino.org" },
    { firstName: "Simona", lastName: "Russo", personalEmail: "s.russo@turismotorino.org" },
    { firstName: "Luella", lastName: "Angioni", personalEmail: "l.angioni@turismotorino.org" },
    { firstName: "Barbara", lastName: "Scalarone", personalEmail: "b.scalarone@turismotorino.org"},
    { firstName: "Manuela", lastName: "Gai", personalEmail: "m.gai@turismotorino.org" },
    { firstName: "Mirella", lastName: "Di Rago", personalEmail: "m.dirago@turismotorino.org" },
    { firstName: "Herik", lastName: "Gottero", personalEmail: "h.gottero@turismotorino.org" },
    { firstName: "Giovanna", lastName: "Barraco", personalEmail: "g.barraco@turismotorino.org" },
    { firstName: "Cristina", lastName: "Cerutti", personalEmail: "c.cerutti@turismotorino.org" },
    { firstName: "Veronica", lastName: "Rossi", personalEmail: "v.rossi@turismotorino.org" },
    { firstName: "Antonella", lastName: "D'amore", personalEmail: "a.damore@turismotorino.org" },
    { firstName: "Marcella", lastName: "Gaspardone", personalEmail: "m.gaspardone@turismotorino.org" },
    { firstName: "Elisa", lastName: "Saporiti", personalEmail: "e.saporiti@turismotorino.org" },
    { firstName: "Mirella", lastName: "Adriano", personalEmail: "m.adriano@turismotorino.org" },
    { firstName: "Nicoletta", lastName: "Paternò", personalEmail: "n.paterno@turismotorino.org" },
    { firstName: "Alessandro", lastName: "Paralovo", personalEmail: "a.paralovo@turismotorino.org" },
    { firstName: "Sara", lastName: "Fiorentino", personalEmail: "s.fiorentino@turismotorino.org" },
    { firstName: "Gloria", lastName: "Faccio", personalEmail: "g.faccio@turismotorino.org" },
    { firstName: "Laura", lastName: "Rosso", personalEmail: "l.rosso@turismotorino.org" },
    { firstName: "Dario", lastName: "Negro", personalEmail: "d.negno@turismotorino.org"},
    { firstName: "Lorenzo",lastName:"Mortera", personalEmail: "l.mortera@turismotorino.org", activityEmail: "info.claviere@turismotorino.org" },
    { firstName: "Lucia", lastName: "Mancuso", personalEmail:"l.mancuso@turismotorino.org" },
    {activityEmail: "scolastico@turismotorino.org"},
    {activityEmail: "convention@turismotorino.org"},
    {activityEmail: "s.lanza@turismotorino.org"},
    {activityEmail: "webteam@turismotorino.org"},
];

export const contactsAssegna = [
    {activityEmail: "info.bardonecchia@turismotorino.org"},
    {activityEmail: "info.cesana@turismotorino.org"},
    {activityEmail: "info.claviere@turismotorino.org"},
    {activityEmail: "info.ivrea@turismotorino.org"},
    {activityEmail: "info.pinerolo@turismotorino.org"},
    {activityEmail: "info.pragelato@turismotorino.org"},
    {activityEmail: "info.sauze@turismotorino.org"},
    {activityEmail: "info.sestriere@turismotorino.org"},
    {activityEmail: "info.susa@turismotorino.org"},
    {activityEmail: "info.torino@turismotorino.org"},
    {activityEmail: "scolastico@turismotorino.org"},

    {activityEmail: "info.desk@turismotorino.org"},
    {activityEmail: "reshotel@turismotorino.org"},
    {activityEmail: "shop@turismotorino.org"},
    {activityEmail: "resguide@turismotorino.org"},
    {activityEmail: "promozione@turismotorino.org"},
    {activityEmail: "convention@turismotorino.org"},
    {activityEmail: "s.lanza@turismotorino.org"},
    {activityEmail: "c.cerutti@turismotorino.org"},
    {activityEmail: "a.paralovo@turismotorino.org"},
    {activityEmail: "s.fiorentino@turismotorino.org "},
    {activityEmail: "n.paterno@turismotorino.org"},
    {activityEmail: "f.sibilla@turismotorino.org"},
    {activityEmail: "f.borini@turismotorino.org"},
];

export const firme = [
    { sede: "Torino", nome: "b.lovecchio - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "b.lovecchio - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "c.varetto - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "c.varetto - resguide", firma: "resguide@turismotorino.org" },
    { sede: "Torino", nome: "c.varetto - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "e.alessi - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "e.alessi - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "e.carosso - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "e.carosso - resguide", firma: "resguide@turismotorino.org" },
    { sede: "Torino", nome: "e.carosso - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "s.contarino - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "s.contarino - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "g.barraco - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "m.buzio - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "m.buzio - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "m.buzio - shop", firma: "shop@turismotorino.org" },
    { sede: "Torino", nome: "m.piras - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "m.piras - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "m.piras - shop", firma: "shop@turismotorino.org" },
    { sede: "Torino", nome: "Mice", firma: "convention@turismotorino.org" },
    { sede: "Torino", nome: "Promozione", firma: "promozione@turismotorino.org" },
    { sede: "Torino", nome: "s.marretta - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "s.marretta - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "l.mancuso - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "l.mancuso - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Bardonecchia", nome: "a.timon - bardonecchia", firma: "info.bardonecchia@turismotorino.org" },
    { sede: "Bardonecchia", nome: "g.callet - bardonecchia", firma: "info.bardonecchia@turismotorino.org" },
    { sede: "Bardonecchia", nome: "r.deyme - bardonecchia", firma: "info.bardonecchia@turismotorino.org" },
    { sede: "Cesana T.se", nome: "a.milia - cesana", firma: "info.cesana@turismotorino.org" },
    { sede: "Cesana T.se", nome: "b.zacchero - cesana", firma: "info.cesana@turismotorino.org" },
    { sede: "Claviere", nome: "l.rivetti - claviere", firma: "info.claviere@turismotorino.org" },
    { sede: "Claviere", nome: "l.mortera - claviere", firma: "info.claviere@turismotorino.org" },
    { sede: "Ivrea", nome: "c.cossano - ivrea", firma: "info.ivrea@turismotorino.org" },
    { sede: "Ivrea", nome: "i.negrinotti - ivrea", firma: "info.ivrea@turismotorino.org" },
    { sede: "Pinerolo", nome: "d.benech - pinerolo", firma: "info.pinerolo@turismotorino.org" },
    { sede: "Pinerolo", nome: "g.armando - pinerolo", firma: "info.pinerolo@turismotorino.org" },
    { sede: "Pinerolo", nome: "m.buzio - pinerolo", firma: "info.pinerolo@turismotorino.org" },
    { sede: "Pragelato", nome: "a.rostan - pragelato", firma: "info.pragelato@turismotorino.org" },
    { sede: "Pragelato", nome: "m.berton - pragelato", firma: "info.pragelato@turismotorino.org" },
    { sede: "Pragelato", nome: "m.blanc - pragelato", firma: "info.pragelato@turismotorino.org" },
    { sede: "Sauze d'Oulx", nome: "c.eydallin - sauze", firma: "info.sauze@turismotorino.org" },
    { sede: "Sauze d'Oulx", nome: "f.perron - sauze", firma: "info.sauze@turismotorino.org" },
    { sede: "Sestriere", nome: "a.valsesia - sestriere", firma: "info.sestriere@turismotorino.org" },
    { sede: "Sestriere", nome: "s.crespi - sestriere", firma: "info.sestriere@turismotorino.org" },
    { sede: "Susa", nome: "s.pecchenino - susa", firma: "info.susa@turismotorino.org" },
    { sede: "Susa", nome: "s.sibille - susa", firma: "info.susa@turismotorino.org" }
];

export const emailVolontari = [
    "volontariverditoyou@gmail.com",
    "volontariportanuovatoyou@gmail.com",
    "volontaricastellotoyou@gmail.com"
    ];