// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-image {
    max-width: 100%;
    height: auto;
}

@media (max-width: 800px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar .nav {
    flex-direction: row;
    justify-content: space-around;
  }
  .sidebar .nav-item {
    flex: 1 1;
    text-align: center;
  }
  .sidebar .nav-link {
    padding: 10px;
  }
}

@media (max-width: 1000px) {
  .sidebar .nav-link {
    font-size: 0.7rem;
    white-space: nowrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;EACE;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,mBAAmB;IACnB,6BAA6B;EAC/B;EACA;IACE,SAAO;IACP,kBAAkB;EACpB;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,mBAAmB;EACrB;AACF","sourcesContent":[".responsive-image {\n    max-width: 100%;\n    height: auto;\n}\n\n@media (max-width: 800px) {\n  .sidebar {\n    width: 100%;\n    height: auto;\n    position: relative;\n  }\n  .sidebar .nav {\n    flex-direction: row;\n    justify-content: space-around;\n  }\n  .sidebar .nav-item {\n    flex: 1;\n    text-align: center;\n  }\n  .sidebar .nav-link {\n    padding: 10px;\n  }\n}\n\n@media (max-width: 1000px) {\n  .sidebar .nav-link {\n    font-size: 0.7rem;\n    white-space: nowrap;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
